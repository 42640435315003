// import BitcoinSeedWebM from "../img/BitcoinSeedOfLife.webm";
import BitcoinSeedMP4 from "../../assets/img/BitcoinSeedOfLife.mp4";
import TimeChainLogo from "../../assets/img/TimeChainLogo.png"


const Video = () => {

  return (
    <>
      <video 
        src={BitcoinSeedMP4} type='video/mp4;'
        autoPlay loop muted playsInline 
        // width={1587.43}
        // height={1736.29}
        placeholdersrc={TimeChainLogo}
        alt="Image Loading"
        className="LifesSeed"
        style={{zIndex:1}}
        >
      </video>
    </>
  );
};

export default Video;