import React from "react";
import "./product.css";
import { Container, Col, Row } from "react-bootstrap";
import TimeChainLogo from "../../assets/img/Icon-TimeChain.svg";
import AmberApp from "../../assets/img/Icon-AmberApp.svg";
import AmberAppPopout from "../../assets/img/AmberAppPopout.png";
import VoltPay from "../../assets/img/Icon-AmberPay.svg";
// import VoltPayPopout from "../../assets/img/VoltPayPopout.png";
import TribeTalkMic from "../../assets/img/TribeTalkMic.png";
import TribeTalk from "../../assets/img/Icon-TribeTalk.svg";
import TribeTalkTweet from "../../assets/img/TribeTalkTweet.png";
import AmberVault from "../../assets/img/Icon-AmberVault.svg";
import TribeKey from "../../assets/img/Icon-TribeKey.svg";
import SovereignSats from "../../assets/img/Icon-AmberP2P.svg";
// import KangarooFund from "../../assets/img/KangarooFundLogo.png";
import JaiBitcoin from "../../assets/img/Icon-AmberExchange.svg";
import NeighbourhoodNodes from "../../assets/img/Icon-AmberNodes.svg";
import AmberNodesPopout from "../../assets/img/AmberNodesPopout.svg";
import SatAttire from "../../assets/img/Icon-TribeMerch.svg";
import { Helmet } from "react-helmet-async";

const Product = () => {
  return (
    <div className="ProductRoadmap" id="ProductRoadmap">
      <Helmet>
        {/* <!-- Primary Meta Tags --> */}
        <title>TimeChain Technologies</title>
        <meta name="title" content="TimeChain Technologies" />
        <meta
          name="description"
          content="Our mission is to help everyone understand and utilise the awesome technology that is Bitcoin."
        />

        {/* <!-- Open Graph / Facebook --> */}
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://timechaintechnologies.com/products"
        />
        <meta property="og:title" content="TimeChain Technologies" />
        <meta
          property="og:description"
          content="Our mission is to help everyone understand and utilise the awesome technology that is Bitcoin."
        />
        <meta
          property="og:image"
          content="https://timechaintechnologies.com/TimechainTechnology.jpg"
        />

        {/* <!-- Twitter --> */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta
          property="twitter:url"
          content="https://timechaintechnologies.com/products"
        />
        <meta property="twitter:title" content="TimeChain Technologies" />
        <meta
          property="twitter:description"
          content="Our mission is to help everyone understand and utilise the awesome technology that is Bitcoin."
        />
        <meta
          property="twitter:image"
          content="https://timechaintechnologies.com/TimechainTechnology.jpg"
        />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, user-scalable=yes"
        />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, user-scalable=yes"
        />
      </Helmet>
      <Container className="TitleAndLogo">
        <Row className="pt-5 pb-4">
          <Col md="12" className="text-center">
            <a href="/" className="TCTSubtitleProductDiv">
              <img
                src={TimeChainLogo}
                alt={<p>TimeChain Logo</p>}
                className="TimeChainImage"
              />
              <h1 className="CompanyTitleTCT">
                <span className="CompanyTitleBoldTCT">TimeChain</span>
                Technologies
              </h1>
              <p className="TCTSubtitleHome">Set up your bitcoin life</p>
            </a>
          </Col>
        </Row>
      </Container>

      {/* <Container className="ProductBody"> */}
        

      <div className="ProductH2">
        <h4 className="Introducing">Introducing</h4>
      </div>

      <Container className="allcards">
        <Row class="g-4 gy-3" className="CurrentRow">
          <div class="col-12 col-md-6 col-lg-4 mx-auto">
            <div class="card h-100" className="Card">
              <div class="card-body">
                <img
                  src={AmberApp}
                  alt="AmberApp Logo"
                  className="ProductLogo"
                />
                <h2 className="ProductTitle" class="card-title">
                  <span className="ProductTitleBold">Amber</span>
                  App
                </h2>
                <div className="Subtitle">
                  <h5 className="ProductTagline" class="card-subtitle">
                    BITCOIN FOR YOU
                  </h5>
                </div>
                <p className="ProductDescription" class="card-text">
                  Launched in 2019, the world's first ever Bitcoin DCA app. Renowned for its instant automations, high-rating customer service and simplicity.
                </p>
                <div className="AmberAppProductLinkDiv">
                  <a
                    href="https://amber.app/"
                    target="_blank"
                    rel="noreferrer"
                    className="AmberAppProductLink"
                  >
                    <img
                      src={AmberAppPopout}
                      alt="popout symbol"
                      className="AmberAppPopout"
                    />
                    AMBER.APP
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12 col-md-6 col-lg-4 mx-auto">
            <div
              class="card h-100"
              className="Card"
              id="TribeCardResponsiveTablet"
            >
              <div class="card-body">
                <div>
                  <img
                    src={TribeTalk}
                    alt="TribeTalk Logo"
                    className="ProductLogo"
                  />
                </div>
                <h2 className="ProductTitle" class="card-title">
                  <span className="ProductTitleBold">Tribe</span>
                  Talk
                </h2>
                <div className="Subtitle">
                  <h5 className="ProductTagline" class="card-subtitle">
                    Gradually then suddenly
                  </h5>
                </div>
                <p className="ProductDescription" class="card-text">
                  Internet portal for global connections. A popular Twitter Spaces and podcast (launch in Q2 2024). An avenue to learn, teach and share experiences as we travel along our Bitcoin Hero’s Journey.
                </p>
                <div className="TribeTalkLinkDiv">
                  <a
                    href="https://twitter.com/theamberapp"
                    className="TribeTalkProductLink"
                  >
                    <img
                      src={TribeTalkMic}
                      alt="Mic symbol"
                      className="Podcast"
                    />
                    PODCAST
                  </a>
                  <a
                    href="https://twitter.com/theamberapp"
                    className="TribeTalkProductLinkTwo"
                  >
                    <img
                      src={TribeTalkTweet}
                      alt="Twitter symbol"
                      className="TwitterSpaces"
                    />
                    TWITTER SPACES
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12 col-md-6 col-lg-4 mx-auto">
            <div class="card h-100" className="Card">
              <div class="card-body">
                <img
                  src={NeighbourhoodNodes}
                  alt="Neighbourhood Nodes Logo"
                  className="ProductLogo"
                />
                <div className="NNDiv">
                  <h2 className="NNProductTitle">
                    <span className="NNProductTitleBold">Amber</span>
                    <br></br>
                    Nodes
                  </h2>
                  <h2 className="NNProductTitleTwo">
                    <span className="NNProductTitleBoldTwo">Amber</span>
                    <span className="NNProductTitleTwoNodes">Nodes</span>
                  </h2>
                </div>
                <div className="Subtitle">
                  <h5 className="ProductTagline" class="card-subtitle">
                    We are the network
                  </h5>
                </div>
                <p className="ProductDescription" class="card-text">
                  A “Don’t Trust, Verify”, Plug-and-Play node + software bundle offering world class support. Use our simple interface to verify your Bitcoin transactions seamlessly and instantly.
                </p>
              </div>
              <div className="AmberNodesProductLinkDiv">
                  <a
                    href="https://amber.app/nodes/"
                    target="_blank"
                    rel="noreferrer"
                    className="AmberNodesProductLink"
                  >
                    <img
                      src={AmberNodesPopout}
                      alt="popout symbol"
                      className="AmberAppPopout"
                    />
                    AMBER.APP
                  </a>
                </div>
            </div>
          </div>

         
        </Row>
      </Container>

      <div className="CurrentlyBuilding">
        <h4 className="ProductListingTwo">Building</h4>
      </div>

      

      <Container className="allcards">
        <Row class="g-4 gy-3 " className="Building">
          <div class="col-12 col-md-6 col-lg-4 mx-auto">
            <div class="card h-100" className="Card">
              <div class="card-body">
                <img
                  src={AmberVault}
                  alt="AmberVault Logo"
                  className="ProductLogo"
                />
                <h2 className="ProductTitle" class="card-title">
                  <span className="ProductTitleBold">Amber</span>
                  Vault
                </h2>
                <div className="Subtitle">
                  <h5 className="ProductTagline" class="card-subtitle">
                    Secure Your Bitcoin
                  </h5>
                </div>
                <p className="ProductDescription" class="card-text">
                World class cold storage vaulting to keep your bitcoin safe. A globally-distributed custodial multi-signature solution, as you level up on your Bitcoin hero's journey. 
                </p>
              </div>
            </div>
          </div>
        
          <div class="col-12 col-md-6 col-lg-4 mx-auto">
            <div class="card h-100" className="Card">
              <div class="card-body">
                <img
                  src={TribeKey}
                  alt="Tribe Key Logo"
                  className="ProductLogo"
                />
                <h2 className="ProductTitle" class="card-title">
                  <span className="ProductTitleBold">Tribe</span>
                  Key
                </h2>
                <div className="Subtitle">
                  <h5 className="ProductTagline" class="card-subtitle">
                    Vires in Numeris
                  </h5>
                </div>
                <p className="ProductDescription" class="card-text">
                  The absolute best Bitcoin collaborative custody multi-sig system with a premiere educational platform. Personal and accessible, offering the experience of self custody anonymously or with a chosen guardian.
                </p>
              </div>
            </div>
          </div>

          <div class="col-12 col-md-6 col-lg-4 mx-auto">
            <div class="card h-100" className="Card">
              <div class="card-body">
                <img
                  src={SatAttire}
                  alt="VoltPay Logo"
                  className="ProductLogo"
                />
                <h2 className="ProductTitle" class="card-title">
                  <span className="ProductTitleBold">Tribe</span>
                  Merch
                </h2>
                <div className="Subtitle">
                  <h5 className="ProductTagline" class="card-subtitle">
                    Expose yourself
                  </h5>
                </div>
                <p className="ProductDescription" class="card-text">
                  To accentuate your orange glowing Bitcoin heart. Well cut, sustainable cotton and bamboo designs. Opsec-friendly and brazenly apparent merchandise, so you can always dress to impress!
                </p>
                {/* <div className="VoltPayProductLinkDiv">
                  <a
                    href="https://tribemerch.store/"
                    target="_blank"
                    rel="noreferrer"
                    class="btn btn-primary"
                    className="VoltPayProductLink"
                  >
                    <img
                      src={VoltPayPopout}
                      alt="popout symbol"
                      className="VoltPayPopout"
                    />
                    TRIBEMERCH.STORE
                  </a>
                </div> */}
              </div>
            </div>
          </div>
        </Row>
      </Container>

      <div className="CurrentlyBuilding">
        <h4 className="ProductListingTwo">Planning</h4>
      </div>

      <Container className="allcardstwo">
        <Row class="g-4 gy-3 " className="Building justify-content-center">
          <div class="col-12 col-md-6 col-lg-4">
            <div class="card h-100" className="Card">
              <div class="card-body">
                <img
                  src={JaiBitcoin}
                  alt="Jai Bitcoin Logo"
                  className="ProductLogo"
                />
                <h2 className="ProductTitle" class="card-title">
                  <span className="ProductTitleBold">Amber</span>
                  Exchange
                </h2>
                <div className="Subtitle">
                  <h5 className="ProductTagline" class="card-subtitle">
                    Victory to Bitcoin
                  </h5>
                </div>
                <p className="ProductDescription" class="card-text">
                  A simple and secure non-custodial exchange allowing anyone to buy and sell bitcoin instantly. Victory to Bitcoin is through self custody, privacy and it's the way of sovereignty.
                </p>
              </div>
            </div>
          </div>

          <div class="col-12 col-md-6 col-lg-4 mx-auto">
            <div class="card h-100" className="Card">
              <div class="card-body">
                <img src={VoltPay} alt="VoltPay Logo" className="ProductLogo" />
                <h2 className="ProductTitle" class="card-title">
                  <span className="ProductTitleBold">Amber</span>
                  Pay
                </h2>
                <div className="Subtitle">
                  <h5 className="ProductTagline" class="card-subtitle">
                    Bitcoin for Businesses
                  </h5>
                </div>
                <p className="ProductDescription" class="card-text">
                  An instant Point-of-Sale Bitcoin app. Accepting payments made
                  simple for global businesses leveraging the transformative
                  power of the Lightning Network. Build & manage your product
                  catalogue.
                </p>
                {/* <div className="VoltPayProductLinkDiv">
                  <a
                    href="https://amber.app/"
                    target="_blank"
                    rel="noreferrer"
                    class="btn btn-primary"
                    className="VoltPayProductLink"
                  >
                    <img
                      src={VoltPayPopout}
                      alt="popout symbol"
                      className="VoltPayPopout"
                    />
                    AMBER.APP
                  </a>
                </div> */}
              </div>
            </div>
          </div>

          <div class="col-12 col-md-6 col-lg-4">
            <div class="card h-100" className="Card">
              <div class="card-body">
                <img
                  src={SovereignSats}
                  alt="Sovereign Sats Logo"
                  className="ProductLogo"
                />
                <h2 className="ProductTitle" class="card-title">
                  <span className="ProductTitleBold">Amber</span>
                    P2P
                </h2>
                <div className="Subtitle">
                  <h5 className="ProductTagline" class="card-subtitle">
                    Peer-to-Peer Bitcoin
                  </h5>
                </div>
                <p className="ProductDescription" class="card-text">
                  A Bitcoin Peer-to-Peer exchange for anyone, anywhere. Simply
                  swipe left or right to match. Multi-sig escrow capability in a
                  super simple app. Verifiably reduces trust to a minimum.
                </p>
              </div>
            </div>
          </div>
        </Row>
      </Container>

      {/* <div className="CurrentlyBuilding">
        <h4 className="ProductListingTwo">Future</h4>
      </div>

      <Container className="allcardstwo">
        <Row class="g-4 gy-3 " className="Building">
        <div class="col-12 col-md-6 col-lg-4 mx-auto">
            <div class="card h-100" className="Card">
              <div class="card-body">
                <img
                  src={KangarooFund}
                  alt="KangarooFund Logo"
                  className="ProductLogo"
                />
                <h2 className="ProductTitle" class="card-title">
                  <span className="ProductTitleBold">Amber</span>
                  Vault
                </h2>
                <div className="Subtitle">
                  <h5 className="ProductTagline" class="card-subtitle">
                    An Orange New World
                  </h5>
                </div>
                <p className="ProductDescription" class="card-text">
                  For a bright orange world. A Bitcoin VC fund for savvy minded
                  investors. Southern Hemisphere based, globally focused.
                  Accessible for premiere exposure to companies building on the best performing asset on earth.
                </p>
              </div>
            </div>
          </div>
        </Row>
      </Container> */}

      <Container className="FooterIPTwo">
        <Row class="g-4 gy-3">
          <Col md="12" className="text-center">
            <h4 className="Contact">Contact us</h4>
            <div className="ClosingInfo">
              <a
                href="mailto:hello@timechaintechnologies.com"
                className="Email"
              >
                hello@timechaintechnologies.com
              </a>
            </div>
            <h4 className="Copyright">©2023 TimeChain Technologies, Inc.</h4>
            <img
              src={TimeChainLogo}
              alt="TimeChain Footer Logo"
              className="TimeChainImageFooter"
            />
          </Col>
        </Row>
      </Container>
      {/* </Container> */}
    </div>
  );
};
export default Product;
