import React from "react";
import "./timechain.css";
import Video from "../../components/BackgroundVideo/Background";
import TimeChainLogo from "../../assets/img/Icon-TimeChain.svg";
import "@fontsource/ibm-plex-sans";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import { Container, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";

const TimeChain = () => {
  return (
    <div className="TCTHome">
      <Helmet>
        {/* <!-- Primary Meta Tags --> */}
        <title>TimeChain Technologies</title>
        <meta name="title" content="TimeChain Technologies" />
        <meta
          name="description"
          content="Our mission is to help everyone understand and utilise the awesome technology that is Bitcoin."
        />

        {/* <!-- Open Graph / Facebook --> */}
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://timechaintechnologies.com"
        />
        <meta property="og:title" content="TimeChain Technologies" />
        <meta
          property="og:description"
          content="Our mission is to help everyone understand and utilise the awesome technology that is Bitcoin."
        />
        <meta
          property="og:image"
          content="https://timechaintechnologies.com/TimechainTechnology.jpg"
        />

        {/* <!-- Twitter --> */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta
          property="twitter:url"
          content="https://timechaintechnologies.com"
        />
        <meta property="twitter:title" content="TimeChain Technologies" />
        <meta
          property="twitter:description"
          content="Our mission is to help everyone understand and utilise the awesome technology that is Bitcoin."
        />
        <meta
          property="twitter:image"
          content="https://timechaintechnologies.com/TimechainTechnology.jpg"
        />
      </Helmet>

      <div className="TimeChain" id="videobackground">
        <Video className="Seed" />
        <Container>
          <Row className="pt-5 pb-4">
            <Col md="12" className="text-center">
              <a href="/" className="TCTSubtitleHomeDiv">
                <img
                  src={TimeChainLogo}
                  alt={<p>TimeChain Logo</p>}
                  className="TimeChainImageTCT"
                />
                <h1 className="CompanyTitleTCT">
                  <span className="CompanyTitleBoldTCT">TimeChain</span>
                  Technologies
                </h1>
                <p className="TCTSubtitleHome">set up your bitcoin life</p>
              </a>
            </Col>
          </Row>
        </Container>
        <div></div>
        <Container className="SetupDiv">
          <Row className="pt-5 pb-4">
            <Col md="12" className="text-center">
              <h2 className="Setup">Helping you understand Bitcoin</h2>
              <Link to="/products#" className="HeroJourney">
                View Suite of Products
              </Link>
            </Col>
          </Row>
        </Container>
        <Container className="JourneyDiv">
          <Row className="pt-5 pb-4">
            <Col md="12" className="text-center">
              <h2 className="Journey">The journey starts here</h2>
              <Link to="/roadmap#" className="HeroJourney" id="DesktopHJ">
                View Roadmap
              </Link>
            </Col>
          </Row>
        </Container>
        <Container className="FooterIP">
          <Row className="pt-5 pb-4">
            <Col md="12" className="text-center">
              <h4 className="Contact">Contact us</h4>
              <div className="ClosingInfo">
                <a
                  href="mailto:hello@timechaintechnologies.com"
                  className="Email"
                >
                  hello@timechaintechnologies.com
                </a>
              </div>
              <h4 className="Copyright">©2023 TimeChain Technologies, Inc.</h4>
              <img
                src={TimeChainLogo}
                alt="TimeChain Footer Logo"
                className="TimeChainImageFooter"
              />
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default TimeChain;
