import React, { useEffect, useRef, useState } from "react";
import "./herojourney.css";
import { Container } from "react-bootstrap";
import TimeChainLogo from "../../assets/img/Icon-TimeChain.svg";
import { Helmet } from "react-helmet-async";
import SVGHeroJourney from './SVGHerojourney.js';

const HeroJourney = () => {
  const [showGrayDiv, setShowGrayDiv] = useState(false);
  const [grayDivPosition, setGrayDivPosition] = useState({ x: 0, y: 0 });
  const [grayDivText, setGrayDivText] = useState("");
  const [grayDivTitle, setGrayDivTitle] = useState("");

  const grayDivRef = useRef();

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (showGrayDiv && grayDivRef.current && !grayDivRef.current.contains(e.target)) {
        setShowGrayDiv(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [showGrayDiv]);

  const handleOnClickOrTouch = (circleX, circleY, title, text) => {
    const svgRect = document.getElementById("HJTitleDiv").getBoundingClientRect();
    console.log("handleOnClickOrTouch called");
    console.log("circleX:", circleX);
    console.log("circleY:", circleY);
    console.log("title:", title);
    console.log("text:", text);

    let x = circleX + svgRect.left;
    let y = circleY + svgRect.top;

    x -= grayDivRef.current.offsetWidth / 2;
    y -= grayDivRef.current.offsetHeight / 2;

    setShowGrayDiv(true);
    setGrayDivPosition({ x, y });
    setGrayDivTitle(title);
    setGrayDivText(text);
  };

  return (
    <>
    <div className="HJMainDiv">
      <Helmet>
        {/* <!-- Primary Meta Tags --> */}
        <title>TimeChain Technologies</title>
        <meta name="title" content="TimeChain Technologies" />
        <meta
          name="description"
          content="Our mission is to help everyone understand and utilise the awesome technology that is Bitcoin."
        />

        {/* <!-- Open Graph / Facebook --> */}
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://timechaintechnologies.com/roadmap"
        />
        <meta property="og:title" content="TimeChain Technologies" />
        <meta
          property="og:description"
          content="Our mission is to help everyone understand and utilise the awesome technology that is Bitcoin."
        />
        <meta
          property="og:image"
          content="https://timechaintechnologies.com/TimechainTechnology.jpg"
        />

        {/* <!-- Twitter --> */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta
          property="twitter:url"
          content="https://timechaintechnologies.com/roadmap"
        />
        <meta property="twitter:title" content="TimeChain Technologies" />
        <meta
          property="twitter:description"
          content="Our mission is to help everyone understand and utilise the awesome technology that is Bitcoin."
        />
        <meta
          property="twitter:image"
          content="https://timechaintechnologies.com/TimechainTechnology.jpg"
        />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, user-scalable=yes"
        />
      </Helmet>

      <Container className="HJTitleAndLogo container">
        <div className="HJTitleAndLogo">
          <div className="HJLogoDiv">
            <a href="/" className="TCTSubtitleProductDiv">
              <img src={TimeChainLogo} alt="TimeChain Logo" className="TimeChainImage" />
              <h1 className="CompanyTitleTCT">
                <span className="CompanyTitleBoldTCT">TimeChain</span>
                Technologies
              </h1>
              <p className="TCTSubtitleHome">set up your bitcoin life</p>
            </a>
          </div>
        </div>
      </Container>

      <div className="HJTitleDiv" id="HJTitleDiv" style={{ margin: "60px" }}>
        <SVGHeroJourney />
      </div>

      {/* Here is where the grayDiv is being placed */}
      <div
        ref={grayDivRef}
        className={`grayDiv ${showGrayDiv ? "show" : ""}`}
        style={{ left: `${grayDivPosition.x}px`, top: `${grayDivPosition.y}px` }}
      >
        <h3>{grayDivTitle}</h3>
        <p>{grayDivText}</p>
      </div>
      
    </div>
    </>
  );
};

export default HeroJourney;
