import "./App.css";
import TimeChainTechnologies from "./pages/TimeChainTechnologies/TimeChain";
import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Product from "./pages/Roadmap/Product";
import Roadmap from "./pages/HeroJourney/HeroJourney";
import { HelmetProvider } from "react-helmet-async";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";

function App() {
  useEffect(() => {
    document.title = "TimeChain Technologies";
  }, []);

  return (
    <div className="App">
      <HelmetProvider>
        <BrowserRouter>
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<TimeChainTechnologies />} />
            <Route path="/products" element={<Product />} />
            <Route path="/roadmap" element={<Roadmap />} />
          </Routes>
        </BrowserRouter>
      </HelmetProvider>
    </div>
  );
}

export default App;
